import AdsBanner from "@components/AdsBanner/AdsBanner"
import AwardsTimeline from "@components/AwardsTimeline/AwardsTimeline"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import Layout from "@components/Layout/Layout"
import TileBlock from "@components/TileBlock/TileBlock"
import TitleBlock from "@components/TitleBlock/TitleBlock"
import Seo from "@components/seo"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React from "react"

const AwardsStaticLanding = ({ data }) => {
  const pageData = data?.strapiPage
  const menuData = pageData?.menu
  const modules = pageData?.modules
  const imagetransforms = pageData?.ggfx_results
  const strapiID = pageData?.strapi_id

  return (
    <Layout pageData={pageData}>
      <Breadcrumbs menuData={menuData} />
      {modules?.map((module) => (
        <React.Fragment key={module.id}>
          {module?.strapi_component === "modules.advanced-slider" && (
           <TitleBlock module={module} ggfx_results={imagetransforms}
           strapiID={strapiID} />
          )}
          {module?.strapi_component === "modules.year-award" && (
            <AwardsTimeline imagetransforms={imagetransforms}
            strapiID={strapiID} module={module} />
          )}
          {module?.strapi_component === "modules.ads-banner" && (
            <AdsBanner module={module} />
          )}
         
          {module?.strapi_component === "modules.tile-block" && (
            <TileBlock
              module={module}
              ggfx_results={imagetransforms}
              strapiID={strapiID}
            />
          )}
        
        </React.Fragment>
      ))}
    </Layout>
  )
}

export default AwardsStaticLanding

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_ADS_BANNER {
          ...adsBanner
        }
       ... on STRAPI__COMPONENT_MODULES_ADVANCED_SLIDER {
          ...advancedSliderFragment
        }
        ... on STRAPI__COMPONENT_MODULES_YEAR_AWARD {
          ...yearAward
        }
        ... on STRAPI__COMPONENT_MODULES_TILE_BLOCK {
          ...tileBlock
        }
       
      }
    }
  }
`
